import { gql } from '@apollo/client'
import {
  AccountFragment,
  BookingFragment,
  ResourceFragment,
  BookingResourceFragment,
} from './fragments'

export const CREATE_GUEST_BOOKING = gql`
  mutation createGuestBooking($booking: GuestBookingInput!) {
    createGuestBooking(booking: $booking) {
      id
      bookingToken
    }
  }
`

//
export const CREATE_GUEST_PAYMENT = gql`
  mutation payGuestBooking(
    $id: ID!
    $token: String!
    $booking: GuestPaymentInput!
  ) {
    payGuestBooking(id: $id, token: $token, booking: $booking) {
      id
      bookingToken
    }
  }
`

//
export const GET_BOOKING_BY_TOKEN = gql`
  query currentBooking($token: String) {
    booking: currentBooking(token: $token) {
      ...BookingFragment
      account {
        ...AccountFragment
      }
      bookingResources {
        ...BookingResourceFragment
        resource {
          ...ResourceFragment
        }
      }
    }
  }
  ${BookingFragment}
  ${AccountFragment}
  ${ResourceFragment}
  ${BookingResourceFragment}
`
